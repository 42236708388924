import React from 'react';
import Layout from '../components/Layout';

export default function InvestorsPage() {
  return (
    <>
      <Layout />
      <p>Hey! I'm the investors page.</p>
    </>
  );
}